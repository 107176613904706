import { render, staticRenderFns } from "./couponList.vue?vue&type=template&id=397a3388&scoped=true&"
import script from "./couponList.vue?vue&type=script&lang=js&"
export * from "./couponList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "397a3388",
  null
  
)

export default component.exports