<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
      <el-form-item label="优惠券名称" prop="name">
        <el-input class="w220" v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="优惠券类型" prop="couponType">
        <el-select v-model="ruleForm.couponType" placeholder="请选择">
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="ruleForm.couponType">
        <el-col :span="6">
          <el-input
              v-model="ruleForm.minAmount"
              class="input-with-select"
          >
            <template slot="prepend">满</template>
          </el-input>
        </el-col>
        <el-col class="line" :span="2"  v-if="ruleForm.couponType==='1'">元，减</el-col>
        <el-col class="line" :span="2" v-else>元，打</el-col>
        <el-col :span="6">
        <el-input v-if="ruleForm.couponType==='1'" v-model="ruleForm.couponFaceValue">
          <template slot="append">元</template>
        </el-input>
        <el-input v-else  v-model="ruleForm.couponDiscount"
                  :min='1'
                  :step='0.1'
                  :max='9.99'>
          <template slot="append">折</template>
        </el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="使用范围" prop="rangeType">
        <el-radio
          class="mr10"
          style="margin: 0"
          v-model="ruleForm.rangeType"
          label="1"
          @change="changeRangeType"
          >全场通用</el-radio
        >
      </el-form-item>
      <el-form-item>
        <el-radio v-model="ruleForm.rangeType" label="2" @change="changeRangeType">指定分类使用</el-radio>
        <el-select
            style="width: 150px"
            :disabled="ruleForm.rangeType!== '2'"
            v-model="ruleForm.goodtypeId"
            placeholder="选择商品分类"
        >
          <el-option
              v-for="(item, index) in categoriesArr"
              :key="index"
              :label="item.name"
              :value="item.goodstypeId"
          >
          </el-option>
        </el-select>
        专用
      </el-form-item>
      <el-form-item>
        <el-radio v-model="ruleForm.rangeType" label="3" @change="changeRangeType">指定商品使用</el-radio>
        <el-select
            style="width: 150px"
            :disabled='ruleForm.rangeType!== "3"'
            v-model="ruleForm.goodsId"
            placeholder="选择商品"
        >
          <el-option
              v-for="(item, index) in goodsListArr"
              :key="index"
              :label="item.goodsName"
              :value="item.goodsId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发劵日期" prop="grantTime">
        <el-date-picker
          v-model="ruleForm.grantTime"
          class="mr10"
          align="right"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00','23:59:59']"
          :picker-options="pickerOptions"
        />
      </el-form-item>
      <el-form-item label="使用有效日期" prop="effectiveDate">
        <el-date-picker
          v-model="ruleForm.effectiveDate"
          class="mr10"
          align="right"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00','23:59:59']"
          :picker-options="pickerOptions"
        />
      </el-form-item>
      <el-form-item label="发放方式" prop="grantMode">
        <!-- <el-radio v-model="ruleForm.grantMode" label="0"
          >新用户赠劵</el-radio
        > -->
        <el-radio v-model="ruleForm.grantMode" label="1">首页赠劵</el-radio>
        <el-radio v-model="ruleForm.grantMode" label="2">后台发放</el-radio>
      </el-form-item>
      <el-form-item label="发放数量" prop="couponNum">
        <el-input
          class="w220"
          type="number"
          placeholder="请输入发放数量"
          v-model="ruleForm.couponNum"
        />
        张
      </el-form-item>
      <el-form-item label="同一用户最多发放" prop="maxUser">
        <el-input
          class="w220"
          type="number"
          placeholder="请输入"
          v-model="ruleForm.maxUser"
        />
        张
      </el-form-item>
      <el-form-item label="发放状态" prop="pubState">
        <el-switch
          v-model="ruleForm.pubState"
          active-text="启用"
          inactive-text="禁用"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="备注" prop="note">
        <el-input
          type="textarea"
          class="w220"
          autosize
          placeholder="备注内容"
          v-model="ruleForm.note"
        >
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getGoodsTypeInfo, fetchGoodsListAll } from '@/api/coupon';
export default {
  name: "addCoupon",
  data() {
    let scopeRules = (rule, value, callback) => {
      if(this.ruleForm.rangeType == 2 && this.ruleForm.goodtypeId == ''){
        callback(new Error('请选择商品分类'));
      }else if( this.ruleForm.rangeType == 3 && this.ruleForm.goodsId == '') {
        callback(new Error('请选择商品'));
      }else{
        callback();
      }
    }
    let checkAmount = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入金额'));
        }else{
          let reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
          if(reg.test(value)){
            if(parseInt(this.ruleForm.couponFaceValue) > parseInt(this.ruleForm.minAmount)) {
              callback(new Error('满减额应该小于最低消费额'))
            }
            callback()
          }else {
            return callback(new Error('请输入正确数值'));
          }
        }
      };
     let checkTime = (rule, value, callback) =>{
       if (!value) {
          return callback(new Error('请选择时间'));
       }else {
         console.log('发券日期', this.ruleForm.grantTime)
         console.log('生效日期',this.ruleForm.effectiveDate)
         let grantEndTime = new Date(this.ruleForm.grantTime[1]).getTime()
         let effectiveEndDate = new Date(this.ruleForm.effectiveDate[1]).getTime()
         if(grantEndTime - effectiveEndDate > 0) {
           return callback(new Error('发放优惠券最晚时间应该小于优惠券优惠的最晚时间'));
         }else {
           callback()
         }
       }
     }
    return {
      options: [
        {
          label: "满减券",
          value: "1",
        },
        {
          label: "折扣券",
          value: "2",
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      categoriesArr: [],
      goodsListArr: [],
      ruleForm: {
        name: "",
        minAmount: "",
        couponFaceValue: "",
        couponDiscount:'',
        rangeType: "1",
        goodtypeId: '', // 按分类 2
        goodsId: '', // 按商品3
        couponType: "",
        grantTime: "",
        effectiveDate: "",
        grantMode: "1",
        couponNum: "",
        maxUser: "",
        pubState: false,
        note: "",
      },
      rules: {
        name:[
          { required: true, message: "请输入优惠券名称" }
        ],
        minAmount:[
          // { required: true, message: "请输入优惠劵最低消费金额" },
          { validator: checkAmount, trigger: 'blur' }
        ],
        couponType:[
          { required: true, message: "请选择优惠券类型" }
        ],
        couponFaceValue:[
          // { required: true, message: "请输入优惠券面值" }
          { validator: checkAmount, trigger: 'blur' }
        ],
        rangeType:[
          { validator: scopeRules, required: true }
        ],
        grantTime:[
          // { required: true, message: "请选择发劵日期" },
          { validator: checkTime, trigger: 'blur' }
        ],
        effectiveDate:[
          // { required: true, message: "请选择使用有效日期" }
          { validator: checkTime, trigger: 'blur' }
        ],
        grantMode:[
          { required: true, message: "请选择发放方式" }
        ],
        couponNum:[
          { required: true, message: "请输入发放数量" }
        ],
        maxUser:[
          { required: true, message: "请输入最多发放数量" }
        ],
        pubState:[
          { required: true, message: "请选择发放状态" }
        ],
        couponDiscount:[
          { required: true, message: "请输入折扣" }
        ],
      }
    };
  },
  props:{
    bEdit:{
      type:Boolean,
      default:false
    }
  },
  created(){
    this.getGoodsTypeInfo();
    // 获取商品列表不分页
    this.getGoodsList()
  },
  methods:{
    changeRangeType(check) {
      if(check == 2) {
        this.ruleForm.goodsId = ""
      }else if(check == 3) {
        this.ruleForm.goodtypeId = ""
      }else {
        this.ruleForm.goodsId = ""
        this.ruleForm.goodtypeId = ""
      }
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if(this.bEdit){
            this.$emit("onConfirmEdit", this.ruleForm);
          }else{
            this.$emit("submitForm", this.ruleForm);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async getGoodsTypeInfo(){
       const result = await getGoodsTypeInfo({ignore:true});
       this.categoriesArr = result.data;
    },
    async getGoodsList() {
       const result = await fetchGoodsListAll({ignore:true});
       this.goodsListArr = result.data;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>
